import React, { useEffect, useCallback, useState } from 'react';

import {
  Button,
  ButtonGroup,
  Dialog,
  DialogMessage,
  DialogTitle,
  Overlay,
  InputObs,
} from './styles';

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string;
  onConfirm: (motivo: string) => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  const [motivo, setMotivo] = useState('');

  const aoAlterarMotivo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMotivo(event.target.value);
    },
    [],
  );

  useEffect(() => {
    if (open) {
      setMotivo('');
    }
  }, [open]);

  return (
    <Overlay open={open}>
      <Dialog>
        <DialogTitle>{title}</DialogTitle>
        <DialogMessage>{message}</DialogMessage>
        <InputObs
          variant="outlined"
          margin="normal"
          id="motivo"
          label="Motivo"
          name="motivo"
          value={motivo}
          onChange={aoAlterarMotivo}
        />
        <ButtonGroup>
          <Button variant="cancel" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            variant="confirm"
            onClick={() => {
              onConfirm(motivo);
            }}
          >
            Confirmar
          </Button>
        </ButtonGroup>
      </Dialog>
    </Overlay>
  );
};

export default ConfirmationModal;
