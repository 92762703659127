import React, { useEffect } from 'react';
import { useToast } from '../../context/ToastContext';
import { returnAxiosError } from '../../utils/returnAxiosError';
import api from '../../services/api';
import { useState, useCallback } from 'react';

import {
  Container,
  ContainerInputs,
  InputNome,
  SalvarButton,
  Pesquisa,
  CardCategoria,
  GroupBox,
  GroupBoxLegenda,
  ToggleCircle,
  ToggleContainer,
  ToggleText,
} from './styles';

import ICategoria from '../../routes/vms/ICategoria';

import ColorPicker from '../../components/colorPicker';

import { FiTrash2 } from 'react-icons/fi';

const Categoria: React.FC = () => {
  const [categorias, setCategorias] = useState<ICategoria[]>([]);
  const [nome, setNome] = useState('');
  const [cor, setCor] = useState('');
  const [selecionada, setSelecionada] = useState<ICategoria | null>(null);
  const [inutilizar, setInutilizar] = useState(false);

  const { addToast } = useToast();

  const updateLista = useCallback(() => {
    api
      .get<ICategoria[]>('/categoria/lista', { params: { inutilizar } })
      .then(response => {
        setCategorias(response.data);
      });
  }, [inutilizar]);

  useEffect(() => {
    updateLista();
  }, [updateLista]);

  const handleSalvarCategoria = useCallback(async () => {
    try {
      if (selecionada) {
        await api.put('/categoria/' + selecionada.id, {
          nome,
          cor,
          inutilizar,
        });
      } else {
        await api.post('/categoria', { nome, cor, inutilizar });
      }
    } catch (error) {
      addToast(returnAxiosError(error));

      return;
    }

    addToast({
      type: 'success',
      title: 'Categoria salva!',
    });

    setNome('');
    setCor('');
    setSelecionada(null);
    updateLista();
  }, [cor, addToast, nome, updateLista, selecionada, inutilizar]);

  const handleExcluirCategoria = useCallback(
    async (id: string) => {
      try {
        await api.delete('/categoria/' + id);
      } catch (error) {
        addToast(returnAxiosError(error));

        return;
      }

      addToast({
        type: 'success',
        title: 'Categoria excluída!',
      });

      setNome('');
      setCor('');
      setSelecionada(null);
      updateLista();
    },
    [addToast, updateLista],
  );

  const handleCorChange = useCallback((cor: string) => {
    setCor(cor);
  }, []);

  return (
    <Container>
      <GroupBox>
        <GroupBoxLegenda>Categoria</GroupBoxLegenda>

        <ContainerInputs>
          <span>
            <InputNome
              variant="outlined"
              margin="normal"
              id="nome"
              label="Nome Categoria"
              name="nomeCategoria"
              type="text"
              value={nome}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNome(e.target.value)
              }
            />

            <ToggleContainer
              onClick={() => {
                setInutilizar(!inutilizar);
              }}
            >
              <ToggleCircle inutilizado={inutilizar} />
              <ToggleText inutilizado={inutilizar}>
                {inutilizar ? 'Bloqueada' : 'Normal'}
              </ToggleText>
            </ToggleContainer>
          </span>

          <ColorPicker onChange={handleCorChange} cor={cor} />

          <SalvarButton onClick={handleSalvarCategoria}>Salvar</SalvarButton>
        </ContainerInputs>

        <Pesquisa>
          {categorias.map(categoria => (
            <CardCategoria
              key={categoria.id}
              cor={categoria.cor}
              onClick={() => {
                setNome(categoria.nome);
                setCor(categoria.cor);
                setSelecionada(categoria);
              }}
            >
              <p>{categoria.nome}</p>
              <FiTrash2
                onClick={() => {
                  handleExcluirCategoria(categoria.id);
                }}
              />
            </CardCategoria>
          ))}
        </Pesquisa>
      </GroupBox>
    </Container>
  );
};

export default Categoria;
