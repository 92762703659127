import styled, { keyframes, css } from 'styled-components';
import { tint } from 'polished';

import { FiMenu, FiClock, FiChevronLeft, FiChevronRight } from 'react-icons/fi';

interface ToggleProps {
  inutilizado: boolean;
}

interface ColumnProps {
  expanded?: boolean;
  visible: boolean;
}

interface WaitProps {
  categoryColor: string;
}

interface VisionProps {
  active: boolean;
}

interface AgendaSlotProps {
  cor?: string;
  inutilizado?: boolean;
  agendado: boolean;
}

interface CarrouselItemProps {
  selected: boolean;
}

const surgirDeCima = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

const detectBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome')) {
    return 'chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'firefox';
  } else if (userAgent.includes('Safari')) {
    return 'safari';
  } else {
    return 'other'; // Para outros navegadores
  }
};

const getScrollbarStyles = (browser: string) => {
  if (browser !== 'firefox') {
    return css`
      /* Estilizando o Scrollbar para Chrome/Safari */
      &::-webkit-scrollbar {
        width: 5px;
        height: 10px; /* Para barras de rolagem horizontais */
      }

      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        background-color: ${props =>
          props.theme.secundaria}; /* Cor do "thumb" */
        border-radius: 10px;
        border: 3px solid transparent;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Cor do "track" */
      }

      /* Remove as setas do scrollbar em WebKit */
      &::-webkit-scrollbar-button {
        display: none; /* Remove as setas do scrollbar */
      }
    `;
  } else {
    return css`
      /* Estilizando o Scrollbar para Firefox */
      scrollbar-width: thin; /* Largura do scrollbar: "auto" ou "thin" */
      scrollbar-color: ${props => props.theme.secundaria} #f0f0f0; /* Cor do thumb e do track */
    `;
  }
};

const IconStyle = css`
  cursor: pointer;

  height: 40px;
  width: 20px;

  align-self: center;
`;

const carrousselButtonStyles = css`
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  color: var(--color-blue);
  cursor: pointer;
`;

const sidebarStyles = css<ColumnProps>`
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  width: ${props => (props.expanded ? '300px' : '10px')};

  p {
    align-self: center;
    animation: ${fadeIn} 2s;

    font-size: 18px;
    font-weight: 600;
  }

  @media (max-width: 610px) {
    width: ${props => (props.expanded ? '100%' : '10px')};
  }
  transition: width 0.4s ease;

  border-right: 1px solid lightgray;

  padding: 15px;

  overflow-y: auto;
  overflow-x: hidden;

  position: relative;

  ${getScrollbarStyles(detectBrowser())};
`;

export const SetaEsquerdaIcon = styled(FiChevronLeft)`
  ${carrousselButtonStyles}
`;

export const SetaDireitaIcon = styled(FiChevronRight)`
  ${carrousselButtonStyles}
`;

export const MenuIcon = styled(FiMenu)`
  ${IconStyle}
`;

export const RelogioIcon = styled(FiClock)`
  ${IconStyle}
`;

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  overflow: hidden;
`;

export const AgendaContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 90px;
  height: calc(100% - 90px);

  animation: ${surgirDeCima} 1s;

  @media (max-width: 610px) {
    margin-top: 60px;
    height: calc(100% - 60px);
  }
`;

export const CalendarioColumn = styled.div`
  ${sidebarStyles}

  gap: 5px;
`;

export const ListaEsperaColumn = styled.div`
  ${sidebarStyles}
`;

export const CabecalhoColunaLateral = styled.div`
  display: flex;
  align-items: center;

  align-self: flex-end;
`;

export const AgendaColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow-x: auto;
`;

export const Cabecalho = styled.header`
  /* fixar na tela */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  padding: 10px;

  --gradient-color: ${props => props.theme.primaria};
  background: linear-gradient(to right, white, var(--gradient-color));
  /* width: auto; */
  flex: 1;

  border-bottom: 3px solid ${props => props.theme.secundaria};

  animation: ${surgirDeCima} 1s;

  height: 95px;
  @media (max-width: 610px) {
    height: 65px;
    padding: 5px;
  }
`;

export const ConteudoCabecalho = styled.div`
  /* dá uma alinhada no centro no header content */
  /*margin: 0 auto;*/
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* estiliza a primeira imagem: a logo */
  a {
    img {
      height: 75px;
      width: 75px;

      @media (max-width: 610px) {
        width: 50px;
        height: 50px;
      }

      cursor: pointer;
    }
  }
`;

export const Perfil = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 10px;

  img {
    height: 50px;
    width: 50px;

    border-radius: 100%;

    @media (max-width: 610px) {
      width: 35px;
      height: 35px;
    }

    margin-right: 10px;
    margin-bottom: 5px;
  }

  span {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 14px;
    @media (max-width: 610px) {
      font-size: 12px;
    }
    font-weight: bold;
  }
`;

export const ConteudoPerfil = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  div {
    padding: 2px;

    button {
      display: flex;
      align-items: center;

      background-color: transparent;
      border: none;
      color: ${props => props.theme.secundaria};
      font-size: 20px;

      &:hover {
        color: ${props => tint(0.4, props.theme.secundariaShade)};

        svg {
          fill: ${props => tint(0.4, props.theme.secundariaShade)};
        }
      }

      svg {
        fill: ${props => props.theme.secundaria};

        height: 22px;
        width: 22px;
        margin-right: 5px;
      }

      span {
        font-size: 20px;
      }

      @media (max-width: 610px) {
        svg {
          height: 18px;
          width: 18px;
        }

        span {
          font-size: 15px;
        }
      }
    }
  }
`;

export const BotoesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  margin-bottom: 10px;
`;

export const FiltrosContainer = styled.div`
  .MuiTextField-root {
    /* contorno do input */
    & .MuiOutlinedInput-root {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.theme.secundaria} !important;
        }
      }
    }
    /* Contorno do input no hover */
    &:hover {
      & .MuiOutlinedInput-root {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: ${props => props.theme.secundariaShade} !important;
          }
        }
      }
    }
    /* contorno quando foca no input */
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: ${props => props.theme.secundariaShade} !important;
      }
    }
    /* cor da fonte do input */
    & .MuiOutlinedInput-input {
      color: ${props => props.theme.texto} !important;
      /* letra maiúscula no input */
      text-transform: uppercase;
    }
    /* label quando preenchido */
    & label.MuiFormLabel-filled {
      color: ${props => props.theme.secundaria} !important;
    }
    /* label em cima do input */
    & label.Mui-focused {
      color: ${props => props.theme.secundariaShade} !important;
    }
    /* seria o placeholder */
    > label {
      color: ${props => props.theme.textoClaro};
    }
  }
`;

export const BotaoNovo = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  margin-bottom: 5px;

  button {
    background: ${props => props.theme.secundaria};
    border-radius: 8px 8px 8px 8px;
    border: none;
    flex: 1;

    height: 30px;
    color: #fff;

    transition: color 0.3s;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${props => props.theme.secundariaShade};
    }

    &:active {
      background-color: ${props => props.theme.primaria};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }

      span {
        font-size: 18px;
      }
    }
  }
`;

export const TabButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  padding: 8px;

  border: 2px solid ${props => props.theme.secundaria};
  border-radius: 8px;
  background-color: white;
  color: ${props => props.theme.secundaria};
  font-size: 14px;
  cursor: pointer;

  svg {
    height: 30px;
    width: 30px;
  }

  &:hover {
    color: #ffffff;
    background-color: ${props => props.theme.secundaria};
  }
`;

export const Calendario = styled.div`
  align-self: center;
  width: 100%;

  animation: ${fadeIn} 2s;
  margin-bottom: 5px;

  .DayPicker {
    color: ${props => props.theme.secundaria};
    font-size: 13px;

    @media (max-width: 610px) {
      font-size: 20px;
    }

    @media (max-width: 500px) {
      font-size: 17px;
    }

    @media (max-width: 400px) {
      font-size: 15px;
    }

    @media (max-width: 310px) {
      font-size: 13px;
    }

    @media (max-width: 275px) {
      font-size: 11px;
    }

    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
    height: 100%;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 4px;
    margin: 8px;
  }

  .DayPicker-Day {
    width: 25px;
    height: 25px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #ffffff;
    border-radius: 10px;
    color: ${props => props.theme.texto};
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    background: ${props => props.theme.textoClaro};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: ${props => props.theme.secundaria} !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: ${props => props.theme.secundaria} !important;
    border-radius: 10px;
    color: ${props => props.theme.textoClaro} !important;
  }
`;

export const VisoesLinha = styled.div<VisionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 12px;

  border-bottom: 1px solid lightgray;

  animation: ${fadeIn} 2s;
`;

export const ControlDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 25px;

  width: 100px;

  p {
    color: ${props => props.theme.secundaria};
    font-size: 20px;
    font-weight: 600;
  }

  @media (max-width: 610px) {
    display: none;
  }
`;

export const BotoesControle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;

  button {
    display: flex;
    align-items: center;

    background: ${props => props.theme.secundaria};
    border: none;
    flex: 1;

    height: 30px;
    color: #fff;

    transition: color 0.3s;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${props => props.theme.secundariaShade};
    }

    &:active {
      background-color: ${props => props.theme.primaria};
    }
  }

  .todayButton {
    padding: 10px;
  }

  .previewButton {
    padding: 5px;
    border-radius: 8px 0px 0px 8px;
  }

  .nextButton {
    padding: 5px;
    border-radius: 0px 8px 8px 0px;
  }
`;

export const ListaEspera = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  animation: ${fadeIn} 2s;
  height: 100%;

  button {
    height: 40px;

    div {
      span {
        font-weight: 600;
        font-size: 15px;
      }
    }
  }

  p {
    text-align: center;
  }

  @media (max-width: 610px) {
    align-self: center;
    width: 80%;
  }
`;

export const Totalizador = styled.div`
  align-self: center;

  width: 100%;
  padding: 8px;

  p {
    color: ${props => props.theme.secundaria};
    font-weight: bold;
    font-size: 16;
    text-align: center;
  }
`;

export const PacienteEsperando = styled.div<WaitProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  height: 35px;

  font-size: 14px;

  margin: 5px 0px;
  padding: 5px;

  border-radius: 8px;
  background-color: ${props => props.categoryColor};
  color: #fff;

  img {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }
`;

export const PacienteEsperandoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  p {
    color: black;
    font-weight: bold;
  }

  svg {
    cursor: pointer;

    color: ${props => props.theme.secundaria};
    :hover {
      color: ${props => props.theme.secundariaShade};
    }

    height: 20px;
    width: 20px;
  }
`;

export const LinhaAgenda = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;

  animation: ${fadeIn} 2s;

  div {
    display: flex;
    flex-direction: row;
  }

  ${getScrollbarStyles(detectBrowser())};

  @media (max-width: 610px) {
    font-size: 14px;
  }
`;

export const HoraAgenda = styled.p`
  margin-right: 10px;
`;

export const EspacoAgenda = styled.div<AgendaSlotProps>`
  padding: 5px;

  height: 85px;
  border-bottom: 1px solid lightgray;

  background-color: ${props =>
    props.agendado ? tint(0.8, props.cor ? props.cor : '#f8f8f8') : '#f8f8f8'};
  border-left: ${props => (props.agendado ? `5px solid ${props.cor}` : '')};

  width: 100%;
  cursor: pointer;

  &.inutilizado {
    background-color: ${props =>
      props.agendado
        ? tint(0.8, props.cor ? props.cor : '#f8f8f8')
        : '#f8f8f8'};
    border: 3px solid gray;

    p:first-child {
      color: gray;
    }
  }

  p {
    font-size: 18px;
  }
`;

export const EspacoAgendaConteudo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: 10px;
      text-align: center;
    }
  }

  p {
    margin-top: 5px;
    font-weight: 600;
    font-size: 18px;

    @media (max-width: 610px) {
      font-size: 14px;
    }
  }

  p + p {
    font-size: 15px;
  }

  img {
    width: 50px;
    height: 50px;
    @media (max-width: 610px) {
      width: 30px;
      height: 30px;
    }

    object-fit: contain;
  }
`;

export const CarrosselContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
`;

export const Carrossel = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;

  /* Esconde a barra de rolagem */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`;

export const CarrosselItem = styled.div<CarrouselItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  cursor: pointer;
  border-radius: 30px;
  border: none;
  background-color: ${props =>
    props.selected
      ? tint(0.6, props.theme.secundaria ? props.theme.secundaria : 'white')
      : 'lightgray'};
  color: ${props => (props.selected ? props.theme.secundaria : '#a0a0a0')};
  font-weight: 600;

  transition: background-color, color 0.5s ease;

  height: 35px;
  margin: 0px 2.5px;
  padding: 0px 10px;

  text-align: center;

  img {
    border-radius: 50%;
    border: ${props =>
      props.selected ? `1.5px solid ${props.theme.secundaria}` : 'none'};

    left: 0;
    height: 25px;
    width: 25px;
  }

  svg {
    margin-left: 5px;
  }

  @media (max-width: 610px) {
    font-size: 14px;
    height: 30px;

    img {
      height: 20px;
      width: 20px;
    }
  }
`;

export const CarrouselBotao = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 1;

  @media (max-width: 610px) {
    display: none;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
`;

export const TableCell = styled.td`
  border: 1px solid #ddd;
  text-align: center;

  height: 75px;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const LinhaCelular = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-bottom: 1px solid lightgray;

  p {
    color: ${props => props.theme.secundaria};
    font-size: 16px;
    font-weight: bold;
    padding: 5px;
  }

  @media (min-width: 610px) {
    display: none;
  }
`;

export const MenuLinha = styled.div`
  animation: ${fadeIn} 2s;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid lightgray;

  padding: 10px;
`;

export const ControleCelularContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    flex: 1;
    height: 25px;

    background: none;
    border: 1px solid ${props => props.theme.secundaria};
    color: ${props => props.theme.secundaria};
    border-radius: 8px;

    font-size: 14px;
    font-weight: bold;

    padding: 8px;
  }

  svg {
    color: ${props => props.theme.secundaria};
  }
`;

export const ContainerCadastros = styled.div`
  display: flex;
  flex-direction: column;

  height: 100vh;
  overflow: hidden;
  padding: 10px;
`;

export const BotaoMenu = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;

  border: 1px solid ${props => props.theme.secundaria};
  border-radius: 8px;

  background-color: ${props =>
    props.isActive ? props.theme.secundaria : '#f5f5f5'};
  color: ${props => (props.isActive ? '#fff' : props.theme.secundaria)};
  cursor: pointer;

  padding: 10px 20px;
  margin-bottom: 8px;
  font-size: 18px;

  animation: ${fadeIn} 2s;

  svg {
    margin-right: 10%;
    height: 25px;
    width: 25px;
  }

  &:hover {
    background-color: ${props => props.theme.secundaria};
    color: #fff;
  }
`;

export const ToggleContainer = styled.div<ToggleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 105px; /* Ajuste para acomodar o texto */
  height: 36px;
  background: white;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  border: 2px solid
    ${props => (props.inutilizado ? 'gray' : props.theme.secundaria)};
  transition: all 0.4s ease;
`;

export const ToggleCircle = styled.div<ToggleProps>`
  width: 32px;
  height: 32px;
  background: ${props => (props.inutilizado ? 'gray' : props.theme.secundaria)};
  border-radius: 50%;
  position: absolute;
  left: ${props => (props.inutilizado ? '0px' : '69px')}; /* Movimento suave */
  transition: all 0.5s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Adiciona sombra ao círculo */
`;

export const ToggleText = styled.span<ToggleProps>`
  color: ${props => (props.inutilizado ? 'gray' : props.theme.secundaria)};
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  left: ${props =>
    props.inutilizado ? '35px' : '10px'}; /* Posição dinâmica */
  transition: all 0.4s ease;
`;
