import styled, { css, keyframes } from 'styled-components';
import { TextField } from '@material-ui/core';

interface TabButtonProps {
  ativa: boolean;
}

const fadeIn = keyframes`
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

const ButtonStyle = css`
  cursor: pointer;
  animation: ${fadeIn} 1s;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;

  background: ${props => props.theme.secundaria};
  border-radius: 8px;
  border: none;
  flex: 1;
  /* width: 100%; */

  height: 30px;
  color: #fff;

  transition: color 0.3s;
  transition: background-color 0.1s;

  &:hover {
    background-color: ${props => props.theme.secundariaShade};
  }

  &:active {
    background-color: ${props => props.theme.primaria};
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
`;

const detectBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome')) {
    return 'chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'firefox';
  } else if (userAgent.includes('Safari')) {
    return 'safari';
  } else {
    return 'other'; // Para outros navegadores
  }
};

const getScrollbarStyles = (browser: string) => {
  if (browser !== 'firefox') {
    return css`
      /* Estilizando o Scrollbar para Chrome/Safari */
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px; /* Para barras de rolagem horizontais */
      }

      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        background-color: ${props =>
          props.theme.secundaria}; /* Cor do "thumb" */
        border-radius: 10px;
        border: 3px solid transparent;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Cor do "track" */
        margin-bottom: 5px;
      }

      /* Remove as setas do scrollbar em WebKit */
      &::-webkit-scrollbar-button {
        display: none; /* Remove as setas do scrollbar */
      }
    `;
  } else {
    return css`
      /* Estilizando o Scrollbar para Firefox */
      scrollbar-width: thin; /* Largura do scrollbar: "auto" ou "thin" */
      scrollbar-color: ${props => props.theme.secundaria} #f0f0f0; /* Cor do thumb e do track */
    `;
  }
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  animation: ${fadeIn} 2s;
  overflow: hidden;

  .MuiTextField-root {
    /* contorno do input */
    & .MuiOutlinedInput-root {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.theme.secundaria} !important;
        }
      }
    }
    /* Contorno do input no hover */
    &:hover {
      & .MuiOutlinedInput-root {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: ${props => props.theme.secundariaShade} !important;
          }
        }
      }
    }
    /* contorno quando foca no input */
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: ${props => props.theme.secundariaShade} !important;
      }
    }
    /* cor da fonte do input */
    & .MuiOutlinedInput-input {
      color: ${props => props.theme.texto} !important;
      /* letra maiúscula no input */
      text-transform: uppercase;
    }
    /* label quando preenchido */
    & label.MuiFormLabel-filled {
      color: ${props => props.theme.secundaria} !important;
    }
    /* label em cima do input */
    & label.Mui-focused {
      color: ${props => props.theme.secundariaShade} !important;
    }
    /* seria o placeholder */
    > label {
      color: ${props => props.theme.textoClaro};
    }
  }
`;

export const GroupBoxHistorico = styled.fieldset`
  border: 1px solid ${props => props.theme.secundaria} !important;
  border-radius: 10px;
  padding: 10px !important;
  height: 100vh;

  overflow-y: auto;
  ${getScrollbarStyles(detectBrowser())};
`;

export const GroupBoxPaciente = styled.fieldset`
  border: 1px solid ${props => props.theme.secundaria} !important;
  border-radius: 10px;
  padding: 0 10px 10px !important;
  height: 100%;

  overflow-y: auto;
  ${getScrollbarStyles(detectBrowser())};

  span {
    animation: ${fadeIn} 1s;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export const GroupBoxPesquisa = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.secundaria} !important;
  border-radius: 10px;
  padding: 0 10px 10px !important;
  height: 100vh;
  overflow: hidden;

  span {
    animation: ${fadeIn} 1s;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export const GroupBoxContatos = styled.fieldset`
  border: 1px solid ${props => props.theme.secundaria} !important;
  border-radius: 10px;
  padding: 0 15px 15px !important;
  min-height: 200px;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export const GroupBoxLegenda = styled.legend`
  font-size: 20px !important;
  /* font-weight: bold !important; */
  text-align: left !important;
  color: ${props => props.theme.secundaria};
  width: auto;
  padding: 0px 5px;
  border-bottom: none;
`;

export const InputCpf = styled(TextField)`
  flex: 2;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputNome = styled(TextField)`
  flex: 6;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputData = styled(TextField)`
  flex: 2;
  width: 100%;
`;

export const InputNomePai = styled(TextField)`
  flex: 1;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputNomeMae = styled(TextField)`
  flex: 1;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputCep = styled(TextField)`
  flex: 3;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputEndereco = styled(TextField)`
  flex: 8;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputNumero = styled(TextField)`
  flex: 2;
  width: 100%;
`;

export const InputBairro = styled(TextField)`
  flex: 6;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputComplemento = styled(TextField)`
  flex: 2;
  width: 100%;
`;

export const InputCidade = styled(TextField)`
  flex: 4;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputUf = styled(TextField)`
  flex: 1;
  width: 100%;
`;

export const InputTipoContato = styled(TextField)`
  align-self: flex-start;
  flex: 1;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputValorContato = styled(TextField)`
  align-self: flex-start;
  flex: 2;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputObsContato = styled(TextField)`
  align-self: flex-start;
  flex: 1;
`;

export const AddContatoButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  align-self: flex-start;
  height: 56px;
  width: 56px;

  margin-top: 15px;

  border: none;
  border-radius: 8px;
  background-color: ${props => props.theme.secundaria};

  svg {
    height: 30px;
    width: 30px;
    color: #ffffff;
  }

  &:hover {
    background-color: ${props => props.theme.secundariaShade};
  }

  @media (max-width: 768px) {
    margin-left: 8px;
    margin-bottom: 8px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const ListaContatos = styled.div`
  animation: ${fadeIn} 1s;
  max-height: 200px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  overflow-y: auto;
  ${getScrollbarStyles(detectBrowser())};
`;

export const CardContato = styled.div`
  animation: ${fadeIn} 1s;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  flex-wrap: wrap;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 5px;

    padding: 8px;

    border: 1px solid ${props => props.theme.secundaria};
    border-radius: 8px;

    div {
      width: 100%;
      text-align: left;
      border-right: none;
      border-bottom: 1px solid #eee;
      padding: 8px 0;
    }

    svg {
      color: ${props => props.theme.secundaria};
      align-self: flex-end;

      height: 20px;
      width: 20px;
    }
  }
`;

export const TipoContato = styled.div`
  flex: 0.93;
  padding: 18.5px 14px;
  margin-top: 6px;

  display: flex;
  align-items: center;
  border-radius: 8px;

  color: #fff;
  background-color: ${props => props.theme.secundaria};

  height: 56px;
`;

export const InfoContato = styled(TextField)`
  flex: 2;
`;

export const ExcluirContato = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 45px;
  width: 45px;
  border: none;
  border-radius: 50%;

  margin: 0px 5px 0px 5px;

  svg {
    cursor: pointer;

    color: ${props => props.theme.secundaria};
    height: 20px;
    width: 20px;

    &:hover {
      color: ${props => props.theme.secundariaShade};
    }
  }
`;

export const BotoesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  margin-top: 8px;
`;

export const BotaoSalvar = styled.div`
  ${ButtonStyle}
`;

export const BotaoLimpar = styled.div`
  ${ButtonStyle}
`;

export const BotaoExcluir = styled.div`
  ${ButtonStyle}
`;

export const ContainerTabs = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;

  padding: 5px;
  gap: 5px;
`;

export const TabButton = styled.button<TabButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  padding: 8px;

  border: 2px solid
    ${props => (props.ativa ? 'transparent' : props.theme.secundaria)};
  border-radius: 8px;
  background-color: ${props =>
    props.ativa ? props.theme.secundaria : 'white'};
  color: ${props => (props.ativa ? 'white' : props.theme.secundaria)};
  font-size: 14px;
  cursor: pointer;

  svg {
    height: 30px;
    width: 30px;
  }

  &:hover {
    color: #ffffff;
    background-color: ${props => props.theme.secundaria};
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 2;
  width: 100%;

  gap: 8px;
  @media (max-width: 768px) {
    gap: 0;
  }
`;

export const InputNomePesquisa = styled(TextField)`
  flex: 2;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputNomePaiPesquisa = styled(TextField)`
  flex: 2;
  width: 100%;
  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const InputNomeMaePesquisa = styled(TextField)`
  flex: 1;
  width: 100%;
`;

export const PesquisaButton = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;

  height: 56px;
  width: 56px;

  margin-top: 15px;

  border: 2px solid ${props => props.theme.secundaria};
  border-radius: 8px;
  background-color: #fff;

  svg {
    height: 25px;
    width: 25px;
    color: ${props => props.theme.secundaria};
  }

  &:hover {
    background-color: ${props => props.theme.secundaria};
    border: none;

    svg {
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    margin-left: 8px;
    margin-bottom: 8px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const ContainerPesquisa = styled.div`
  animation: ${fadeIn} 1s;

  display: flex;
  flex-direction: column;
  flex: 1;

  overflow-y: auto;
  height: 100%;
  ${getScrollbarStyles(detectBrowser())};
`;

export const CardPacientePesquisa = styled.div`
  animation: ${fadeIn} 1s;

  display: flex;
  flex-direction: column;
  gap: 5px;

  min-height: 95px;
  margin: 0px 5px 5px 0px;

  cursor: pointer;
  padding: 15px;

  border: 1px solid ${props => props.theme.secundaria};
  border-radius: 8px;

  background-color: #ffffff;
  border-radius: 8px;

  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;

  span {
    font-weight: bold;

    display: flex;
    flex-direction: row;
    gap: 5px;

    p:first-child {
      color: ${props => props.theme.secundaria};
    }

    p:nth-child(3) {
      color: black;
    }
  }
`;

export const ContainerHistorico = styled.div`
  display: flex;
  flex-direction: column;

  gap: 5px;
`;

export const CardHistorico = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  padding: 10px;

  border: 1px solid ${props => props.theme.secundaria};
  border-radius: 8px;

  h3 {
    font-weight: bold;
    color: ${props => props.theme.secundaria};
  }

  p:first-of-type {
    font-weight: bold;
  }
`;
