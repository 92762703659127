import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Opcao {
  id: string;
  nome?: string;
  name?: string;
}

interface ComboboxComFiltroProps {
  opcoes: Opcao[];
  value: string | null;
  onChange: (id: string | null) => void;
  error?: boolean;
  helperText?: string;
  label: string;
  temMargin?: boolean;
  size?: number;
  disabled?: boolean;
}

const ComboboxComFiltro: React.FC<ComboboxComFiltroProps> = ({
  opcoes,
  value,
  onChange,
  error = false,
  helperText = '',
  label,
  temMargin = false,
  size = 1,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      style={{
        flex: size,
        width: '100%',
        marginRight: temMargin ? '8px' : '0px',
      }}
      options={opcoes}
      getOptionLabel={option =>
        option.nome ? option.nome : option.name ? option.name : ''
      }
      value={opcoes.find(option => option.id === value) || null}
      onChange={(event, newValue) => {
        if (!disabled) {
          onChange(newValue ? newValue.id : null);
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      noOptionsText="Sem Opções"
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          margin="normal"
          label={label}
          error={error}
          helperText={helperText}
          disabled={disabled}
        />
      )}
    />
  );
};

export default ComboboxComFiltro;
