import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  AddPacienteButton,
  ContainerButton,
  BotaoLimpar,
  BotaoSalvar,
  BotoesContainer,
} from './styles';

import { returnAxiosError } from '../../utils/returnAxiosError';

import Dialog from '../../components/dialog';
import Paciente from '../Paciente';

import api from '../../services/api';
import { useToast } from '../../context/ToastContext';
import { useIndexedDB } from '../../context/IndexedDBContext';
import { useAuth } from '../../context/AuthContext';
import ComboboxComFiltro from '../../components/autocomplete';

import ICategoria from '../../routes/vms/ICategoria';
import IPaciente from '../../routes/vms/IPaciente';

import { FiPlus, FiSave, FiRefreshCw } from 'react-icons/fi';

interface ListaEspera {
  paciente_id: string;
  categoria_id: string;
  ordem: number;
}

interface EsperaProps {
  onClose: () => void;
}

const Espera: React.FC<EsperaProps> = ({ onClose }) => {
  const [categoria, setCategoria] = useState<string | null>(null);
  const [categoriaErro, setCategoriaErro] = useState<string>('');
  const [categorias, setCategorias] = useState<ICategoria[]>([]);

  const [paciente, setPaciente] = useState<string | null>(null);
  const [pacienteErro, setPacienteErro] = useState<string>('');
  const [pacientes, setPacientes] = useState<IPaciente[]>([]);

  const [isDialogOpen, setDialogOpen] = useState(false);

  const { addToast } = useToast();
  const { lerIndexedDB, excluirIndexedDB } = useIndexedDB();
  const { user } = useAuth();

  const handleOpenDialog = () => setDialogOpen(true);

  const handleCloseDialog = useCallback(async () => {
    setDialogOpen(false);

    lerIndexedDB<string>(
      `${user.empresa.url_frontend}:id-paciente-gravado`,
    ).then(idIndexedDB => {
      console.log(idIndexedDB);
      if (idIndexedDB !== null) {
        setPaciente(String(idIndexedDB));
        atualizaDados();

        excluirIndexedDB(`${user.empresa.url_frontend}:id-paciente-gravado`);
      }
    });
  }, [lerIndexedDB, excluirIndexedDB, user.empresa.url_frontend]);

  const aoAlterarCategoria = (novoConvenio: string | null) => {
    setCategoria(novoConvenio);
    setCategoriaErro('');
  };

  const aoAlterarPaciente = (novoPaciente: string | null) => {
    setPaciente(novoPaciente);
    setPacienteErro('');
  };

  const validacoesEspera = useCallback(() => {
    setPacienteErro('');
    setCategoriaErro('');

    let erroValidacao = false;
    if (!categoria) {
      setCategoriaErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (!paciente) {
      setPacienteErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (erroValidacao) {
      addToast({
        type: 'info',
        title: 'Pendências na validação',
        description:
          'Efetue as correções indicadas em cada campo e tente novamente.',
      });
      return false;
    }
  }, [addToast, categoria, paciente]);

  const aoLimparCampos = useCallback(() => {
    setCategoria(null);
    setPaciente(null);
  }, []);

  const aoSalvar = useCallback(async () => {
    if (validacoesEspera() === false) {
      return;
    }

    const response = await api.get<number>('espera/ultimaOrdem');
    const ordem = response.data;

    const esperaGravar: ListaEspera = {
      categoria_id: categoria ? categoria : '',
      paciente_id: paciente ? paciente : '',
      ordem: ordem + 1,
    };

    try {
      await api.post('espera', esperaGravar);
    } catch (error) {
      addToast(returnAxiosError(error));

      return;
    }
    aoLimparCampos();
    onClose();
  }, [
    addToast,
    aoLimparCampos,
    categoria,
    paciente,
    validacoesEspera,
    onClose,
  ]);

  const atualizaDados = async () => {
    try {
      const [categoriasResponse, pacientesResponse] = await Promise.all([
        api.get<ICategoria[]>('/categoria/lista'),
        api.get<IPaciente[]>('/pacientes/'),
      ]);

      setCategorias(categoriasResponse.data);
      setPacientes(pacientesResponse.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  useEffect(() => {
    atualizaDados();
  }, []);

  return (
    <Container>
      <ContainerButton>
        <ComboboxComFiltro
          opcoes={pacientes}
          value={paciente}
          label="Paciente"
          onChange={aoAlterarPaciente}
          error={pacienteErro !== ''}
          helperText={pacienteErro}
        />

        <AddPacienteButton
          title="Cadastrar Paciente"
          onClick={handleOpenDialog}
        >
          <FiPlus />
        </AddPacienteButton>

        <Dialog isOpen={isDialogOpen} onClose={handleCloseDialog}>
          <Paciente externa={true} onClose={handleCloseDialog} />
        </Dialog>
      </ContainerButton>

      <ComboboxComFiltro
        opcoes={categorias}
        value={categoria}
        label="Categoria"
        onChange={aoAlterarCategoria}
        error={categoriaErro !== ''}
        helperText={categoriaErro}
      />

      <BotoesContainer>
        <BotaoSalvar onClick={aoSalvar}>
          <FiSave />
          Salvar
        </BotaoSalvar>

        <BotaoLimpar onClick={aoLimparCampos}>
          <FiRefreshCw />
          Limpar
        </BotaoLimpar>
      </BotoesContainer>
    </Container>
  );
};

export default Espera;
