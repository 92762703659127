import React from 'react';

import { FiXCircle } from 'react-icons/fi';
import { Container, Conteudo, CloseButton } from './style';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactElement<{ onClose: () => void }>;
}

const Dialog: React.FC<DialogProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <Container onClick={onClose}>
      <Conteudo onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <FiXCircle />
        </CloseButton>
        {React.cloneElement(children, { onClose })}
      </Conteudo>
    </Container>
  );
};

export default Dialog;
