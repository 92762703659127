import styled, { css } from 'styled-components';

const detectBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome')) {
    return 'chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'firefox';
  } else if (userAgent.includes('Safari')) {
    return 'safari';
  } else {
    return 'other'; // Para outros navegadores
  }
};

const getScrollbarStyles = (browser: string) => {
  if (browser !== 'firefox') {
    return css`
      /* Estilizando o Scrollbar para Chrome/Safari */
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px; /* Para barras de rolagem horizontais */
      }

      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        background-color: ${props =>
          props.theme.secundaria}; /* Cor do "thumb" */
        border-radius: 10px;
        border: 3px solid transparent;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Cor do "track" */
      }

      /* Remove as setas do scrollbar em WebKit */
      &::-webkit-scrollbar-button {
        display: none; /* Remove as setas do scrollbar */
      }
    `;
  } else {
    return css`
      /* Estilizando o Scrollbar para Firefox */
      scrollbar-width: thin; /* Largura do scrollbar: "auto" ou "thin" */
      scrollbar-color: ${props => props.theme.secundaria} #f0f0f0; /* Cor do thumb e do track */
    `;
  }
};

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const Conteudo = styled.div`
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 35px 16px 16px 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;

  ${getScrollbarStyles(detectBrowser())}
`;

export const CloseButton = styled.button`
  position: absolute; /* Posiciona o botão dentro do .dialog-content */
  top: 8px; /* Espaçamento do topo */
  right: 8px; /* Espaçamento da direita */
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  svg {
    color: ${props => props.theme.secundaria};
    width: 20px;
    height: 20px;
  }

  :hover {
    color: ${props => props.theme.secundariaShade};
  }
`;
