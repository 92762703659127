import styled, { keyframes, css } from 'styled-components';

const fadeIn = keyframes`
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

const ButtonStyle = css`
  cursor: pointer;
  animation: ${fadeIn} 1s;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;

  background: ${props => props.theme.secundaria};
  border-radius: 8px;
  border: none;
  flex: 1;
  /* width: 100%; */

  height: 30px;
  color: #fff;

  transition: color 0.3s;
  transition: background-color 0.1s;

  &:hover {
    background-color: ${props => props.theme.secundariaShade};
  }

  &:active {
    background-color: ${props => props.theme.primaria};
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  animation: ${fadeIn} 2s;

  height: auto;
  width: 650px;
  @media (max-width: 768px) {
    width: 300px;
    height: 100%;
  }
  overflow: hidden;

  span {
    animation: ${fadeIn} 1s;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .MuiTextField-root {
    /* contorno do input */
    & .MuiOutlinedInput-root {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.theme.secundaria} !important;
        }
      }
    }
    /* Contorno do input no hover */
    &:hover {
      & .MuiOutlinedInput-root {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: ${props => props.theme.secundariaShade} !important;
          }
        }
      }
    }
    /* contorno quando foca no input */
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: ${props => props.theme.secundariaShade} !important;
      }
    }
    /* cor da fonte do input */
    & .MuiOutlinedInput-input {
      color: ${props => props.theme.texto} !important;
      /* letra maiúscula no input */
      text-transform: uppercase;
    }
    /* label quando preenchido */
    & label.MuiFormLabel-filled {
      color: ${props => props.theme.secundaria} !important;
    }
    /* label em cima do input */
    & label.Mui-focused {
      color: ${props => props.theme.secundariaShade} !important;
    }
    /* seria o placeholder */
    > label {
      color: ${props => props.theme.textoClaro};
    }
  }
`;

export const AddPacienteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  align-self: flex-start;
  height: 56px;
  width: 56px;

  margin-top: 15px;

  border: none;
  border-radius: 8px;
  background-color: ${props => props.theme.secundaria};

  svg {
    height: 30px;
    width: 30px;
    color: #ffffff;
  }

  &:hover {
    background-color: ${props => props.theme.secundariaShade};
  }

  @media (max-width: 768px) {
    margin: 15px 0px 0px 8px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 1;
  width: 100%;

  gap: 8px;
  @media (max-width: 768px) {
    gap: 0;
  }
`;

export const BotoesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  margin-top: 8px;
`;

export const BotaoSalvar = styled.div`
  ${ButtonStyle}
`;

export const BotaoLimpar = styled.div`
  ${ButtonStyle}
`;
