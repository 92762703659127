import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactElement,
} from 'react';

import api from '../../services/api';

import DayPicker, { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import ComboboxComFiltro from '../../components/autocomplete';
import { TextField } from '@material-ui/core';

import { useConfigs } from '../../context/ConfigsContext';
import { useAuth } from '../../context/AuthContext';
import { useToast } from '../../context/ToastContext';
import { Link, useHistory } from 'react-router-dom';

import Dialog from '../../components/dialog';
import ConfirmationModal from '../../components/confirmationModal';

import Convenio from '../Convenio';
import Categoria from '../Categoria';
import Paciente from '../Paciente';
import Espera from '../Espera';
import Agendamento from '../Agendamento';

import {
  FiLogOut,
  FiPlusCircle,
  FiChevronLeft,
  FiChevronRight,
  FiUser,
  FiUserPlus,
  FiClipboard,
  FiBookmark,
  FiTrash2,
  FiFilter,
  FiRefreshCw,
} from 'react-icons/fi';

import semLogo from '../../assets/sem-logo.png';
import defaultAvatar from '../../assets/default-avatar.png';
import logoImg from '../../assets/mundo-aba-logo.png';

import {
  Container,
  AgendaContainer,
  AgendaColumn,
  Cabecalho,
  ConteudoCabecalho,
  Perfil,
  ConteudoPerfil,
  BotaoNovo,
  BotoesContainer,
  TabButton,
  FiltrosContainer,
  Calendario,
  VisoesLinha,
  LinhaCelular,
  MenuLinha,
  ControleCelularContainer,
  ControlDiv,
  BotoesControle,
  ListaEspera,
  Totalizador,
  PacienteEsperando,
  MenuIcon,
  RelogioIcon,
  LinhaAgenda,
  HoraAgenda,
  EspacoAgenda,
  EspacoAgendaConteudo,
  CabecalhoColunaLateral,
  CalendarioColumn,
  ListaEsperaColumn,
  PacienteEsperandoContainer,
  Carrossel,
  CarrouselBotao,
  CarrosselContainer,
  CarrosselItem,
  SetaEsquerdaIcon,
  SetaDireitaIcon,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  ContainerCadastros,
  BotaoMenu,
  ToggleCircle,
  ToggleContainer,
  ToggleText,
} from './styles';

import IPaciente from '../../routes/vms/IPaciente';
import ICategoria from '../../routes/vms/ICategoria';
import IConvenio from '../../routes/vms/IConvenio';
import IProfissional from '../../routes/vms/IProfissional';
import IAgendamento from '../../routes/vms/IAgendamento';
import { returnAxiosError } from '../../utils/returnAxiosError';

interface ListaEspera {
  id: string;
  empresa_id: string;
  paciente: IPaciente;
  categoria: ICategoria;
  ordem: number;
  data_inclusao: Date;
}

const Agenda: React.FC = () => {
  const { lerConfig } = useConfigs();
  const { user, signOut, verifyAccess } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const diasDesabilitados = [{ daysOfWeek: [0, 6] }];

  const carrosselRef = useRef<HTMLDivElement>(null);
  const [horarios, setHorarios] = useState<string[]>([]);
  const [agendamentos, setAgendamentos] = useState<IAgendamento[]>([]);
  const [datePickerExpandido, setDatePickerExpandido] = useState<boolean>(true);
  const [listaEsperaExpandida, setListaEsperaExpandida] =
    useState<boolean>(false);
  const [dataSelecionada, setDataSelecionada] = useState(new Date());
  const [logoDinamico, setLogoDinamico] = useState<string | undefined>(
    undefined,
  );
  const [isMobile, setIsMobile] = useState(false);
  const [menuSelecionado, setMenuSelecionado] = useState<string | undefined>(
    undefined,
  );
  const [profissionais, setProfissionais] = useState<IProfissional[]>([]);
  const [profissionaisSelecionados, setProfissionaisSelecionados] = useState<
    IProfissional[]
  >([]);
  const [listaEspera, setListaEspera] = useState<ListaEspera[]>([]);
  const [listaEsperaFiltro, setListaEsperaFiltro] = useState<ListaEspera[]>([]);
  const [isEsperaOpen, setEsperaOpen] = useState(false);
  const [isAgendamentoOpen, setAgendamentoOpen] = useState(false);
  const [filtroVisivel, setFiltroVisivel] = useState(false);
  const [nomePesquisa, setNomePesquisa] = useState<string>('');
  const [convenioPesquisa, setConvenioPesquisa] = useState<string | null>(null);
  const [categoriaPesquisa, setCategoriaPesquisa] = useState<string | null>(
    null,
  );
  const [categorias, setCategorias] = useState<ICategoria[]>([]);
  const [convenios, setConvenios] = useState<IConvenio[]>([]);
  const [totalEspera, setTotalEspera] = useState<number>(0);
  const atualizaListaEspera = useCallback(() => {
    api.get<ListaEspera[]>('/espera/listaCompleta').then(response => {
      setListaEspera(response.data);
    });
  }, []);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEspera, setSelectedEspera] = useState<{
    idEspera: string;
    idPaciente: string;
  }>({ idEspera: '', idPaciente: '' });

  const [hora, setHora] = useState('');
  const [profissional, setProfissional] = useState<string>('');
  const [selectedAgendamento, setSelectedAgendamento] = useState<
    IAgendamento | undefined
  >(undefined);
  const [bloquear, setBloquear] = useState(false);

  const defineHorarios = useCallback(async () => {
    const horariosArray = await api.get<string[]>(
      `/empresas/horarios/${user.empresa.url_frontend}`,
    );

    setHorarios(horariosArray.data);
  }, [user]);

  const atualizaProfissionais = useCallback(async () => {
    const response = await api.get<IProfissional[]>('/users/profissionais', {
      params: {
        empresa_id: user.empresa_id,
      },
    });

    setProfissionais(response.data);
  }, [user.empresa_id]);

  const atualizaDados = useCallback(async () => {
    const [categoriasResponse, conveniosResponse] = await Promise.all([
      api.get<ICategoria[]>('/categoria/lista'),
      api.get<IConvenio[]>('/convenio/lista'),
    ]);

    setCategorias(categoriasResponse.data);
    setConvenios(conveniosResponse.data);
  }, []);

  const agendamentosDia = useCallback(async () => {
    const response = await api.get<IAgendamento[]>(
      `/agendamentos/dia?data=${dataSelecionada}`,
    );

    setAgendamentos(response.data);
  }, [dataSelecionada]);

  const handleOpenEspera = () => setEsperaOpen(true);

  const handleCloseEspera = useCallback(() => {
    setEsperaOpen(false);

    atualizaListaEspera();
  }, [atualizaListaEspera]);

  const handleOpenAgendamento = () => setAgendamentoOpen(true);

  const handleCloseAgendamento = useCallback(() => {
    setAgendamentoOpen(false);

    setHora('');
    setProfissional('');
    setSelectedAgendamento(undefined);

    agendamentosDia();
  }, [agendamentosDia]);

  const calculaDiferencaEmDias = useCallback((dataInclusao: Date) => {
    const MILISSEGUNDOS_POR_DIA = 1000 * 60 * 60 * 24;

    const timestampInicial = new Date(dataInclusao).getTime();
    const timestampFinal = new Date().getTime();

    const diferencaEmMilissegundos = timestampFinal - timestampInicial;

    return Math.round(diferencaEmMilissegundos / MILISSEGUNDOS_POR_DIA);
  }, []);

  const handleExcluirEspera = useCallback(
    async (motivo: string) => {
      if (motivo === '') {
        addToast({
          type: 'error',
          title: 'Erro ao excluir',
          description: 'Motivo não informado',
        });

        return;
      }

      try {
        await api.post(`/espera/excluir/${selectedEspera.idEspera}`, {
          motivo: motivo.toUpperCase(),
        });
        atualizaListaEspera();
      } catch (error) {
        addToast(returnAxiosError(error));

        return;
      }

      setModalOpen(false);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Paciente removido da fila de espera',
      });
    },
    [atualizaListaEspera, addToast, selectedEspera],
  );

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return; // O item não foi solto em um lugar válido
      }

      const { source, destination } = result;

      const ordem_atual = listaEsperaFiltro[source.index].ordem;
      const ordem_nova = listaEsperaFiltro[destination.index].ordem;

      try {
        //Estou atualizando local pois quando pega da API para atualizar a lista tem um delay na animação do drag e drop
        const updatedList = [...listaEsperaFiltro];
        const [removed] = updatedList.splice(source.index, 1);
        updatedList.splice(destination.index, 0, removed);
        setListaEsperaFiltro(updatedList);

        await api.patch('/espera/reordenar/', { ordem_atual, ordem_nova });
        atualizaListaEspera();
      } catch (error) {
        atualizaListaEspera();
        addToast(returnAxiosError(error));
      }
    },
    [addToast, atualizaListaEspera, listaEsperaFiltro],
  );

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    if (!carrosselRef.current) return;

    const { current: carrossel } = carrosselRef;

    carrossel.style.scrollBehavior = 'auto';

    const startX = e.pageX - carrossel.offsetLeft;
    const scrollLeft = carrossel.scrollLeft;

    const onMouseMove = (e: MouseEvent) => {
      const x = e.pageX - carrossel.offsetLeft;
      const scroll = x - startX;
      carrossel.scrollLeft = scrollLeft - scroll;
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      carrossel.style.scrollBehavior = 'smooth';
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }, []);

  const scrollLeft = () => {
    if (carrosselRef.current) {
      const { current: carrossel } = carrosselRef;

      const carrosselWidth = carrossel.clientWidth;

      const itemWidth = carrossel.firstElementChild?.clientWidth || 0;

      const itemsPerPage = Math.floor(carrosselWidth / itemWidth);

      carrossel.scrollLeft -= itemsPerPage * itemWidth;
    }
  };

  const scrollRight = () => {
    if (carrosselRef.current) {
      const { current: carrossel } = carrosselRef;

      const carrosselWidth = carrossel.clientWidth;

      const itemWidth = carrossel.firstElementChild?.clientWidth || 0;

      const itemsPerPage = Math.floor(carrosselWidth / itemWidth);

      carrossel.scrollLeft += itemsPerPage * itemWidth;
    }
  };

  const renderizaTelasMenu = useCallback((): ReactElement => {
    let telaCarregar: ReactElement = <></>;

    switch (menuSelecionado) {
      case 'CONVENIO':
        telaCarregar = <Convenio />;
        break;
      case 'CATEGORIA':
        telaCarregar = <Categoria />;
        break;
      case 'PACIENTE':
        telaCarregar = <Paciente />;
    }

    return telaCarregar;
  }, [menuSelecionado]);

  const toggleSelecionado = useCallback(
    (profissional: IProfissional) => {
      if (isMobile) {
        setProfissionaisSelecionados([profissional]);
      } else {
        if (profissionaisSelecionados.some(p => p.id === profissional.id)) {
          // Se o profissional já está selecionado, remove da lista
          setProfissionaisSelecionados(
            profissionaisSelecionados.filter(
              profissionalFiltro => profissionalFiltro.id !== profissional.id,
            ),
          );
        } else {
          // Se não estiver selecionado, adicionar à lista
          setProfissionaisSelecionados([
            ...profissionaisSelecionados,
            profissional,
          ]);
        }
      }

      if (carrosselRef.current) {
        carrosselRef.current.scrollLeft = 0;
      }
    },
    [isMobile, profissionaisSelecionados],
  );

  const toggleListaExpandida = useCallback(() => {
    setListaEsperaExpandida(!listaEsperaExpandida);

    if (isMobile) {
      setDatePickerExpandido(false);
    }
  }, [isMobile, listaEsperaExpandida]);

  const toggleDatePickerExpandido = useCallback(() => {
    setDatePickerExpandido(!datePickerExpandido);

    if (isMobile) {
      setListaEsperaExpandida(false);
    }
  }, [isMobile, datePickerExpandido]);

  const resumeNome = (nomeCompleto: String) => {
    const nomes = nomeCompleto.trim().split(' ');

    if (nomes.length > 1) {
      return `${nomes[0]} ${nomes[1]}`;
    }
  };

  const estaDesabilitado = (date: Date) => {
    const diaSemana = date.getDay();
    return diasDesabilitados.some(desabilitado =>
      desabilitado.daysOfWeek?.includes(diaSemana),
    );
  };

  const vaiParaDiaAnterior = () => {
    let diaAnterior = new Date(dataSelecionada);
    diaAnterior.setDate(dataSelecionada.getDate() - 1);

    while (estaDesabilitado(diaAnterior)) {
      diaAnterior.setDate(diaAnterior.getDate() - 1);
    }

    setDataSelecionada(diaAnterior);
  };

  const vaiParaProximoDia = () => {
    let proximoDia = new Date(dataSelecionada);
    proximoDia.setDate(dataSelecionada.getDate() + 1);

    while (estaDesabilitado(proximoDia)) {
      proximoDia.setDate(proximoDia.getDate() + 1);
    }

    setDataSelecionada(proximoDia);
  };

  const vaiParaHoje = () => {
    let hoje = new Date();

    while (estaDesabilitado(hoje)) {
      hoje.setDate(hoje.getDate() + 1);
    }

    setDataSelecionada(hoje);
  };

  const handleDateChange = useCallback(
    (day: Date, modifiers: DayModifiers) => {
      if (modifiers.available && !modifiers.disabled) {
        setDataSelecionada(day);
      }

      if (isMobile) {
        setDatePickerExpandido(false);
      }

      setMenuSelecionado(undefined);
    },
    [isMobile],
  );

  const handleMenuClick = useCallback(
    (nomeMenu: string) => {
      if (menuSelecionado === nomeMenu) {
        setMenuSelecionado(undefined);
      } else {
        setMenuSelecionado(nomeMenu);
      }

      if (isMobile) {
        setDatePickerExpandido(false);
      }
    },
    [isMobile, menuSelecionado],
  );

  const abrirProfile = useCallback(() => {
    history.push('/profile');
  }, [history]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let configLogo: string | undefined = lerConfig('LOGO');
    if (configLogo && configLogo !== '') {
      api
        .get<string>('/empresas/configs-caminho-arquivo', {
          params: {
            arquivo: configLogo,
          },
        })
        .then(response => {
          setLogoDinamico(response.data);
        });
    } else {
      configLogo = undefined;
    }
  }, [lerConfig]);

  useEffect(() => {
    defineHorarios();
  }, [defineHorarios]);

  useEffect(() => {
    atualizaListaEspera();
  }, [atualizaListaEspera]);

  useEffect(() => {
    atualizaDados();
  }, [atualizaDados]);

  useEffect(() => {
    atualizaProfissionais();

    if (user.profissional) {
      const profissional: IProfissional = {
        id: user.id,
        name: user.name,
      };

      setProfissionaisSelecionados([profissional]);
    }
  }, [atualizaProfissionais, user]);

  useEffect(() => {
    agendamentosDia();
  }, [agendamentosDia]);

  const limparCamposEspera = useCallback(() => {
    setNomePesquisa('');
    setConvenioPesquisa(null);
    setCategoriaPesquisa(null);

    atualizaListaEspera();
  }, [atualizaListaEspera]);

  const aoAlterarConvenioPesquisa = useCallback((id: string | null) => {
    setConvenioPesquisa(id);
  }, []);

  const aoAlterarNomePesquisa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomePesquisa(event.target.value);
    },
    [],
  );

  const pesquisarEspera = useCallback(() => {
    let listaFiltrar = listaEspera;

    if (nomePesquisa) {
      listaFiltrar = listaFiltrar.filter(espera =>
        espera.paciente.nome.toUpperCase().includes(nomePesquisa.toUpperCase()),
      );
    }

    if (convenioPesquisa) {
      listaFiltrar = listaFiltrar.filter(
        espera => espera.paciente.convenio_id === convenioPesquisa,
      );
    }

    if (categoriaPesquisa) {
      listaFiltrar = listaFiltrar.filter(espera =>
        espera.categoria.id
          .toUpperCase()
          .includes(categoriaPesquisa.toUpperCase()),
      );
    }

    setListaEsperaFiltro(listaFiltrar);
    setTotalEspera(listaFiltrar.length);
  }, [nomePesquisa, convenioPesquisa, categoriaPesquisa, listaEspera]);

  useEffect(() => {
    pesquisarEspera();
  }, [pesquisarEspera]);

  const aoAlterarCategoriaPesquisa = useCallback((id: string | null) => {
    setCategoriaPesquisa(id);
  }, []);

  return (
    <Container>
      <Cabecalho>
        <ConteudoCabecalho>
          <Link to="/home">
            <div>
              <img src={logoDinamico ? logoDinamico : logoImg} alt="Logo" />
            </div>
          </Link>

          {user.empresa.utiliza_login === 'S' && (
            <Perfil>
              {/* <ul>
                <li>
                  <button type="button" onClick={abrirProfile}>
                    <FiUser />
                    <span>Perfil</span>
                  </button>
                </li>
                <li>
                  <button type="button" onClick={signOut}>
                    <FiPower />
                    <span>Sair</span>
                  </button>
                </li>
              </ul> */}
              <span>
                <img
                  src={!user.avatar_url ? defaultAvatar : user.avatar_url}
                  alt={user.name}
                />
                <p>{resumeNome(user.name)}</p>
              </span>
              <ConteudoPerfil>
                <div>
                  <button type="button" onClick={abrirProfile}>
                    <FiUser />
                    <span>Perfil</span>
                  </button>
                </div>
                <div>
                  <button type="button" onClick={signOut}>
                    <FiLogOut />
                    <span>Sair</span>
                  </button>
                </div>
              </ConteudoPerfil>
            </Perfil>
          )}
        </ConteudoCabecalho>
      </Cabecalho>

      <AgendaContainer>
        <CalendarioColumn expanded={datePickerExpandido} visible={true}>
          {datePickerExpandido ? (
            <>
              <CabecalhoColunaLateral>
                <MenuIcon
                  onClick={() => {
                    toggleDatePickerExpandido();
                  }}
                />
              </CabecalhoColunaLateral>

              <Calendario>
                <DayPicker
                  weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                  disabledDays={diasDesabilitados}
                  selectedDays={dataSelecionada}
                  month={dataSelecionada}
                  fixedWeeks
                  modifiers={{
                    available: { daysOfWeek: [1, 2, 3, 4, 5] },
                  }}
                  onDayClick={handleDateChange}
                  months={[
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro',
                  ]}
                />
              </Calendario>

              <BotaoMenu
                isActive={menuSelecionado === 'CONVENIO'}
                onClick={() => {
                  handleMenuClick('CONVENIO');
                }}
              >
                <FiBookmark /> Convenio
              </BotaoMenu>

              <BotaoMenu
                isActive={menuSelecionado === 'CATEGORIA'}
                onClick={() => {
                  handleMenuClick('CATEGORIA');
                }}
              >
                <FiClipboard /> Categoria
              </BotaoMenu>

              {verifyAccess('VISUALIZAR CADASTRO DO PACIENTE') && (
                <BotaoMenu
                  isActive={menuSelecionado === 'PACIENTE'}
                  onClick={() => {
                    handleMenuClick('PACIENTE');
                  }}
                >
                  <FiUserPlus /> Paciente
                </BotaoMenu>
              )}
            </>
          ) : (
            <MenuIcon
              onClick={() => {
                toggleDatePickerExpandido();
              }}
            />
          )}
        </CalendarioColumn>

        {verifyAccess('VISUALIZAR LISTA DE ESPERA') && (
          <ListaEsperaColumn
            expanded={listaEsperaExpandida}
            visible={menuSelecionado ? false : true}
          >
            {listaEsperaExpandida ? (
              <>
                <CabecalhoColunaLateral>
                  <RelogioIcon
                    onClick={() => {
                      toggleListaExpandida();
                    }}
                  />
                </CabecalhoColunaLateral>

                <p>Lista de Espera</p>

                <DragDropContext onDragEnd={onDragEnd}>
                  <ListaEspera>
                    <BotoesContainer>
                      <BotaoNovo>
                        <button onClick={handleOpenEspera}>
                          <div>
                            <FiPlusCircle />
                            <span>Espera</span>
                          </div>
                        </button>
                      </BotaoNovo>

                      <TabButton
                        onClick={() => {
                          setFiltroVisivel(!filtroVisivel);
                        }}
                      >
                        <FiFilter />
                      </TabButton>

                      <TabButton
                        onClick={() => {
                          limparCamposEspera();
                        }}
                      >
                        <FiRefreshCw />
                      </TabButton>
                    </BotoesContainer>

                    {filtroVisivel === true ? (
                      <FiltrosContainer>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          id="pacientePesq"
                          label="Paciente"
                          name="pacientePesq"
                          value={nomePesquisa}
                          onChange={aoAlterarNomePesquisa}
                          style={{ width: '100%' }}
                        />

                        <ComboboxComFiltro
                          opcoes={convenios}
                          value={convenioPesquisa}
                          label="Convênio"
                          onChange={aoAlterarConvenioPesquisa}
                        />

                        <ComboboxComFiltro
                          opcoes={categorias}
                          value={categoriaPesquisa}
                          label="Categoria"
                          onChange={aoAlterarCategoriaPesquisa}
                        />
                      </FiltrosContainer>
                    ) : (
                      <></>
                    )}

                    <Totalizador>
                      <p>{`Total em espera: ${totalEspera}`}</p>
                    </Totalizador>

                    <Droppable droppableId="lista-espera">
                      {provided => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {listaEsperaFiltro.length > 0 ? (
                            listaEsperaFiltro.map((espera, index) => (
                              <div key={espera.id}>
                                <Draggable
                                  key={espera.id}
                                  draggableId={espera.id}
                                  index={index}
                                >
                                  {provided => (
                                    <PacienteEsperandoContainer
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <p>{espera.ordem + 'º'}</p>

                                      <PacienteEsperando
                                        title={
                                          espera.categoria.nome +
                                          ' - DIAS ESPERA: ' +
                                          calculaDiferencaEmDias(
                                            espera.data_inclusao,
                                          )
                                        }
                                        data-tip={
                                          espera.categoria.nome +
                                          ' - DIAS ESPERA: '
                                        }
                                        categoryColor={espera.categoria.cor}
                                      >
                                        {resumeNome(espera.paciente.nome)}
                                        <img
                                          src={
                                            espera.paciente.convenio?.logo_url
                                              ? espera.paciente.convenio
                                                  ?.logo_url
                                              : semLogo
                                          }
                                          alt="Logo Convênio"
                                        />
                                      </PacienteEsperando>

                                      <FiTrash2
                                        onClick={() => {
                                          setSelectedEspera({
                                            idEspera: espera.id,
                                            idPaciente: espera.paciente.id,
                                          });
                                          setModalOpen(true);
                                        }}
                                      />
                                    </PacienteEsperandoContainer>
                                  )}
                                </Draggable>
                              </div>
                            ))
                          ) : (
                            <div>
                              <p>
                                Nenhum paciente na lista de espera no momento
                              </p>
                            </div>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </ListaEspera>
                </DragDropContext>
              </>
            ) : (
              <RelogioIcon
                onClick={() => {
                  toggleListaExpandida();
                }}
              />
            )}
          </ListaEsperaColumn>
        )}

        <AgendaColumn>
          {menuSelecionado ? (
            <ContainerCadastros>{renderizaTelasMenu()}</ContainerCadastros>
          ) : (
            <>
              <VisoesLinha active={false}>
                <CarrosselContainer>
                  <CarrouselBotao className="prev" onClick={scrollLeft}>
                    <SetaEsquerdaIcon />
                  </CarrouselBotao>

                  <Carrossel ref={carrosselRef} onMouseDown={handleMouseDown}>
                    {profissionais
                      .sort(
                        (a, b) =>
                          +profissionaisSelecionados.some(p => p.id === b.id) -
                          +profissionaisSelecionados.some(p => p.id === a.id),
                      )
                      .map(profissional => (
                        <CarrosselItem
                          key={profissional.id}
                          onClick={() => {
                            toggleSelecionado(profissional);
                          }}
                          selected={profissionaisSelecionados.some(
                            p => p.id === profissional.id,
                          )}
                        >
                          <img
                            src={
                              profissional.avatar_url
                                ? profissional.avatar_url
                                : defaultAvatar
                            }
                            alt="avatar-profissional"
                          />
                          <p>{resumeNome(profissional.name)}</p>
                        </CarrosselItem>
                      ))}
                  </Carrossel>

                  <CarrouselBotao className="next" onClick={scrollRight}>
                    <SetaDireitaIcon />
                  </CarrouselBotao>
                </CarrosselContainer>
              </VisoesLinha>

              <LinhaCelular>
                <p>
                  {dataSelecionada.toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </p>

                <ControleCelularContainer>
                  <SetaEsquerdaIcon onClick={vaiParaDiaAnterior} />
                  <button onClick={vaiParaHoje}>Voltar para hoje</button>
                  <SetaDireitaIcon onClick={vaiParaProximoDia} />
                </ControleCelularContainer>
              </LinhaCelular>

              <MenuLinha>
                <ToggleContainer
                  inutilizado={bloquear}
                  onClick={() => {
                    setBloquear(!bloquear);
                  }}
                >
                  <ToggleCircle inutilizado={bloquear} />
                  <ToggleText inutilizado={bloquear}>
                    {bloquear ? 'Bloquear' : 'Agendar'}
                  </ToggleText>
                </ToggleContainer>

                <ControlDiv>
                  <BotoesControle>
                    <button
                      className="previewButton"
                      onClick={vaiParaDiaAnterior}
                    >
                      <FiChevronLeft />
                    </button>

                    <button className="todayButton" onClick={vaiParaHoje}>
                      Hoje
                    </button>

                    <button className="nextButton" onClick={vaiParaProximoDia}>
                      <FiChevronRight />
                    </button>
                  </BotoesControle>

                  <p>
                    {dataSelecionada.toLocaleDateString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })}
                  </p>
                </ControlDiv>
              </MenuLinha>

              <LinhaAgenda>
                {profissionaisSelecionados.length === 1 ? (
                  <>
                    {horarios.slice(0, -1).map(horario => {
                      const horarioAgendado = agendamentos.find(
                        agendamento =>
                          agendamento.user?.id ===
                            profissionaisSelecionados[0].id &&
                          agendamento.hora === horario + ':00',
                      );

                      return (
                        <div key={horario}>
                          <HoraAgenda>{horario}</HoraAgenda>
                          <EspacoAgenda
                            onClick={() => {
                              setSelectedAgendamento(horarioAgendado);
                              setHora(horario);
                              setProfissional(profissionaisSelecionados[0].id);
                              handleOpenAgendamento();
                            }}
                            key={horario}
                            agendado={!!horarioAgendado}
                            className={
                              horarioAgendado?.inutilizado
                                ? 'inutilizado'
                                : 'horario'
                            }
                            cor={horarioAgendado?.categoria?.cor}
                          >
                            {horarioAgendado ? (
                              <EspacoAgendaConteudo
                                title={horarioAgendado.observacoes}
                              >
                                {horarioAgendado.inutilizado ? (
                                  <div>
                                    <p>BLOQUEADO</p>
                                    <p>{horarioAgendado.categoria?.nome}</p>
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <p>{horarioAgendado.paciente?.nome}</p>
                                      <p>{horarioAgendado.categoria?.nome}</p>
                                    </div>
                                    <span>
                                      <img
                                        src={
                                          horarioAgendado.paciente?.convenio
                                            ?.logo_url
                                            ? horarioAgendado.paciente?.convenio
                                                ?.logo_url
                                            : semLogo
                                        }
                                        alt="Logo Convênio"
                                      />
                                      <p>
                                        {horarioAgendado.paciente?.convenio
                                          ?.nome
                                          ? horarioAgendado.paciente?.convenio
                                              ?.nome
                                          : ''}
                                      </p>
                                    </span>
                                  </>
                                )}
                              </EspacoAgendaConteudo>
                            ) : (
                              ''
                            )}
                          </EspacoAgenda>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <Table>
                    <thead style={{ position: 'sticky', top: '-11px' }}>
                      <TableRow>
                        <TableHeader>Horários</TableHeader>
                        {profissionaisSelecionados.map(profissional => (
                          <TableHeader key={profissional.id}>
                            {resumeNome(profissional.name)}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </thead>
                    <tbody>
                      {horarios.slice(0, -1).map(horario => (
                        <TableRow key={horario}>
                          <TableCell style={{ width: '40px' }}>
                            {horario}
                          </TableCell>
                          {profissionaisSelecionados.map(profissional => {
                            const horarioAgendado = agendamentos.find(
                              agendamento =>
                                agendamento.user?.id === profissional.id &&
                                agendamento.hora === horario + ':00',
                            );
                            return (
                              <TableCell
                                key={profissional.id}
                                style={{ minWidth: '325px', cursor: 'pointer' }}
                                onClick={() => {
                                  setSelectedAgendamento(horarioAgendado);
                                  setHora(horario);
                                  setProfissional(profissional.id);
                                  handleOpenAgendamento();
                                }}
                              >
                                {horarioAgendado ? (
                                  <EspacoAgenda
                                    key={horario}
                                    agendado={!!horarioAgendado}
                                    cor={horarioAgendado?.categoria?.cor}
                                    title={horarioAgendado.observacoes}
                                    className={
                                      horarioAgendado?.inutilizado
                                        ? 'inutilizado'
                                        : 'horario'
                                    }
                                  >
                                    <EspacoAgendaConteudo>
                                      {horarioAgendado.inutilizado ? (
                                        <div>
                                          <p>BLOQUEADO</p>
                                          <p>
                                            {horarioAgendado.categoria?.nome}
                                          </p>
                                        </div>
                                      ) : (
                                        <>
                                          <div>
                                            <p>
                                              {horarioAgendado.paciente?.nome}
                                            </p>
                                            <p>
                                              {horarioAgendado.categoria?.nome}
                                            </p>
                                          </div>
                                          <span>
                                            <img
                                              src={
                                                horarioAgendado.paciente
                                                  ?.convenio?.logo_url
                                                  ? horarioAgendado.paciente
                                                      ?.convenio?.logo_url
                                                  : semLogo
                                              }
                                              alt="Logo Convênio"
                                            />
                                            <p>
                                              {horarioAgendado.paciente
                                                ?.convenio?.nome
                                                ? horarioAgendado.paciente
                                                    ?.convenio?.nome
                                                : ''}
                                            </p>
                                          </span>
                                        </>
                                      )}
                                    </EspacoAgendaConteudo>
                                  </EspacoAgenda>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                )}
              </LinhaAgenda>
            </>
          )}
        </AgendaColumn>
      </AgendaContainer>

      <Dialog isOpen={isAgendamentoOpen} onClose={handleCloseAgendamento}>
        <Agendamento
          bloquearParam={bloquear}
          onClose={handleCloseAgendamento}
          agendamentoParametro={selectedAgendamento}
          horaParametro={hora}
          profissionalParametro={profissional}
          dataParametro={dataSelecionada}
        />
      </Dialog>

      <Dialog isOpen={isEsperaOpen} onClose={handleCloseEspera}>
        <Espera onClose={handleCloseEspera} />
      </Dialog>

      <ConfirmationModal
        open={modalOpen}
        title="Confirmar Exclusão"
        message="Informe o motivo para excluir esse item"
        onConfirm={motivo => {
          handleExcluirEspera(motivo);
        }}
        onCancel={() => setModalOpen(false)}
      />
    </Container>
  );
};

export default Agenda;
